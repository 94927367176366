@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400&family=Newsreader:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.rotate-icon {
  transition: transform 0.3s ease;
}

.rotate-icon.open {
  transform: rotate(180deg);
}

.font-karla {
  font-family: "Karla", sans-serif;
  letter-spacing: 0.1em;
}

.font-newsreader-light {
  font-family: "Newsreader", serif;
  font-weight: 300;
  letter-spacing: 0em;
}

h1,
h2 {
  span {
    background-size: 100% 24%;
    background-repeat: repeat-x;
    background-position: left 0% bottom 2%;
  }
}

.colored-heading {
  background-image: linear-gradient(90deg, #f7d9d4, #f7d9d4);
}

@keyframes pingPong {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.ms-madi-regular {
  font-family: "Ms Madi", cursive;
  font-weight: 400;
  font-style: normal;
}
